import React, { useState, useEffect } from "react";
import { Global, css } from "@emotion/core";
import emotionReset from "emotion-reset";
import styled from "@emotion/styled";
import Hero from "./components/Hero";
import Products from "./components/Products";
import Content from "./components/Content";
import client from "./SanityClient";

const Loading = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: 3rem;
  }
`;

const Wrapper = styled.div`
  h1 {
    font-weight: 900;
    font-style: normal;
    font-size: 2rem;
    margin: 2rem 0 1rem 0;
  }
  h2 {
    font-weight: 900;
    font-style: normal;
    font-size: 1.6rem;
  }
  p {
    font-weight: 300;
    font-style: normal;
    font-size: 1.2rem;
    line-height: 150%;
    margin: 2rem 0;
  }
  section {
    /* margin: 4rem 1.8rem; */
  }
`;

const Logo = styled.img`
  width: 2.8rem;
  margin: 1.8rem 1.8rem;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  background-color: #f2f2f2;
`;

function App() {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const foo = async () => {
      const result = await client.fetch(
        "*[_type == 'pageDocumentType' && _id == 'start']{headline, body, button, products[]{productName, 'productIcon': productIcon.asset->, productInterval, price, }, info, 'image': figure.image.asset->}"
      );
      setContent(result[0]);
    };
    foo();
  }, []);

  return (
    <>
      <Global
        styles={css`
          ${emotionReset}
          body {
            font-family: proxima-nova, sans-serif;
          }
        `}
      />
      {content ? (
        <Wrapper>
          <a href="https://whatsuphjo.se">
            <Logo src="./logo.svg" alt="Whatsup logotyp" />
          </a>
          <main>
            <Hero {...content} />
            <Products {...content} />
            <Content {...content} />
          </main>
          <Footer>
            <Logo src="./logo-footer.svg" alt="Whatsup logotyp" />
          </Footer>
        </Wrapper>
      ) : (
        <Loading>
          <img src="./loading.svg" alt="Laddar" />
        </Loading>
      )}
    </>
  );
}

export default App;
