import React, { useState } from "react";
import styled from "@emotion/styled";
import SanityClient from "../SanityClient";
import imageUrlBuilder from "@sanity/image-url";
const builder = imageUrlBuilder(SanityClient);

const Wrapper = styled.section`
  margin: 1rem auto 1rem auto;
  max-width: 600px;
  padding: 0 1.8rem;
`;

const ImageWrapper = styled.figure`
  margin: 0;
  position: relative;
  overflow: hidden;
  width: auto;

  & > object {
    position: absolute;
  }

  & > object,
  img {
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 1.2rem;
  }
`;

const imgList = [
  600,
  594,
  512,
  442,
  380,
  328,
  282,
  244,
  210,
  182,
  156,
  134,
  116,
  100,
];

const Hero = ({ image, headline, body }) => {
  const [loaded, setLoaded] = useState(false);

  const hideLQIP = () => {
    setLoaded(true);
  };
  return (
    <Wrapper>
      <ImageWrapper>
        {!loaded ? (
          <object
            data={image.metadata.lqip}
            type="image/jpeg"
            alt="LQIP image"
            aria-label="LQIP image"
          />
        ) : null}
        <picture>
          {imgList.map((width) => (
            <source
              key={width}
              onLoad={hideLQIP}
              media={`(min-width: ${width}px)`}
              srcSet={`${builder
                .image(image)
                .auto("format")
                .width(width)
                .quality(85)
                .dpr(1)
                .url()}, ${builder
                .image(image)
                .auto("format")
                .width(width)
                .quality(85)
                .dpr(2)
                .url()} 2x, ${builder
                .image(image)
                .auto("format")
                .width(width)
                .quality(85)
                .dpr(3)
                .url()} 3x`}
            />
          ))}
          <img
            onLoad={hideLQIP}
            src={builder
              .image(image)
              .auto("format")
              .width(600)
              .quality(80)
              .url()}
            alt="Whatsup hyr ut cyklar, SUP och kajaker"
          />
        </picture>
      </ImageWrapper>
      <div>
        <div>
          <h1>{headline}</h1>
          <p>{body}</p>
        </div>
      </div>
    </Wrapper>
  );
};

export default Hero;
