import React from "react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
const Wrapper = styled.section``;

const Headline = styled.div`
  padding: 1rem 1.8rem 0 1.8rem;
  max-width: 600px;
  margin: 0 auto;
  & > h3 {
    font-weight: 900;
    margin: 1rem 0;
    font-size: 1.2rem;
  }
`;

const ProductWrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  width: 100%;
  padding: 1rem 0;

  &::after {
    content: ".";
    color: transparent;
    flex: 0 0 0;
  }
`;

const Product = styled.div`
  flex-shrink: 0;
  width: 7.2rem;
  margin: 0 1.8rem 0 0;
  &:first-of-type {
    margin-left: 1.4rem;
  }

  & > div:first-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7.2rem;
    height: 7.2rem;
    background-color: #f2f2f2;
    object-fit: cover;
    border-radius: 1.2rem;
    & > img {
      width: 2rem;
    }
  }

  & > div:nth-of-type(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.6rem 0 0.2rem 0;
    & span:first-of-type {
      display: inline-block;
      font-weight: 700;
      font-style: normal;
      font-size: 1.2rem;
      margin: 0 0.2rem;
    }
    & span:last-of-type {
      display: inline-block;
      font-weight: 600;
      font-style: normal;
      font-size: 0.6rem;
      padding: 0.2rem 0.4rem;
      color: #fff;
      background-color: #27b4fd;
      border-radius: 1rem;
      letter-spacing: 0.02rem;
    }
  }
  & > div:last-of-type {
    text-align: center;
    & > span {
      font-size: 1rem;
      font-style: normal;

      color: #585858;
    }
  }
`;

const Button = styled.div`
  display: block;
  padding: 2rem 1.8rem 1rem 1.8rem;
  max-width: 600px;
  margin: 0 auto;
  & > a {
    margin: 0;
    & > button {
      cursor: pointer;
      height: 3.8rem;
      width: 100%;
      border: none;
      border-radius: 1.2rem;
      background-color: #27b4fd;
      font-size: 1.2rem;
      font-weight: 700;
      text-transform: uppercase;
      color: #fff;
    }
  }
`;

const BookingNumber = styled.div`
  padding: 0 1.8rem;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  & > span {
    font-size: 0.8rem;
    color: gray;
  }
`;

const Products = ({ products, button }) => {
  return (
    <Wrapper>
      <Headline>
        <h3>Vårt utbud</h3>
      </Headline>
      <ProductWrapper>
        {products.map((product, index) => (
          <Product key={index}>
            <div>
              <img src={product.productIcon.url} alt="" />
            </div>
            <div>
              <span>{product.productName}</span>
              {product.productInterval ? (
                <span>{product.productInterval}</span>
              ) : null}
            </div>
            <div>
              <span>{`${product.price} kr`}</span>
            </div>
          </Product>
        ))}
      </ProductWrapper>
      <Button>
        <a id="btn" href="tel:0760025405">
          <motion.button whileTap={{ scale: 0.96 }}>{button}</motion.button>
        </a>
      </Button>
      <BookingNumber>
        <span>Bokningsnummer: 076-002 54 05</span>
      </BookingNumber>
    </Wrapper>
  );
};

export default Products;
