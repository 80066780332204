import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.section`
  margin: 4rem auto 4rem auto;
  max-width: 600px;
  padding: 0 1.8rem;
`;

const Content = ({ info }) => {
  return (
    <Wrapper>
      <div>
        {info.map((element, index) => {
          if (element.style === "normal") {
            return <p key={index}>{element.children[0].text}</p>;
          } else {
            return React.createElement(
              element.style,
              { key: index },
              element.children[0].text
            );
          }
        })}
      </div>
    </Wrapper>
  );
};

export default Content;
